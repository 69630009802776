<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Orders/Tranactions"
      :showbroker="false"
      :showaction="false"
      :showback="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip :showBrokerButton="true" />
      </div>

      <div class="col-span-8">
        <span id="download_loader" v-if="!orderData"></span>

        <div v-id="orderData">
          <view-orders :orders="orderData" />
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";
import ViewOrders from "@/components/orders/ViewOrders.vue";
import moment from "moment";

export default {
  data() {
    return {
      orderData: null,
    };
  },
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
    ViewOrders,
  },
  mounted() {
    this.orderData = [];

    // Retail orders

    console.log(this.$store.state);

    this.$store.state.retailorder.forEach((retailOrder) => {
      var orderDate = moment(retailOrder.order_date);
      var description = "";

      retailOrder.items.forEach((orderItem) => {

        description =
          '<b>' +
          orderItem.wines.name +
          " " +
          orderItem.wines.vintage +
          " " +
          orderItem.wines.size.name +
          "ML" +
          "<br/>" + '</b>' +
          " x " + orderItem.quantity;
      });

      var order = {
        order_number: retailOrder.order_number,
        total: retailOrder.total,
        currency: retailOrder.currency,
        order_date: orderDate,
        description: description,
        type: "Retail Purchase",
      };

      console.log(order)

      this.orderData.push(order);
    });

    // Investment orders
    this.$store.state.portfolio.forEach((invest) => {
      var orderDate = moment(invest.order_date);
      var description = "";

      invest.items.forEach((orderItem) => {
        description =
          description +
          orderItem.quantity +
          " x " +
          orderItem.wines.name +
          " " +
          orderItem.wines.vintage +
          " " +
          orderItem.wines.size.name +
          "ML" +
          "<br/>";
      });

      var order = {
        order_number: invest.order_number,
        total: invest.total,
        currency: invest.currency,
        order_date: orderDate,
        description: description,
        type: "Investment Purchase",
      };
      this.orderData.push(order);
    });
  },
  computed: {},
  methods: {},
};
</script>

<style></style>
