

<template>

    <div>


        <div v-if="orders.length > 0">

            <table class="w-full mt-10">
                <thead class="border-b border-black ">
                    <tr>
                        <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3 pl-20">
                            Date
                        </th>
                        <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3">
                            Summary
                        </th>
                        <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3">
                            Charge
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="order in orders" v-bind:key="order.order_number"  class="bg-white border-b border-black">
                        <td class="px-3 py-3 oeno_portfolio_tabledata content-center pl-20"><b>{{order.order_date.format('MMMM Do')}}</b><br/>{{order.order_date.format('YYYY')}}</td>
                        <td class="px-3 py-3 oeno_portfolio_tabledata items-center" v-html="order.description">{{ order.description }}</td>
                        <td class="px-3 py-3 oeno_portfolio_tabledata content-center"><b>{{order.currency}} {{currencyFormatter(order.total)}}</b></td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div v-if="orders.length == 0">
            <h1>No orders on account yet</h1>
        </div>
    </div>
</template>
   
<script>

export default {
    name: 'Vieworders',
    props: {
        orders: Array
    },  
    methods: {
        formatPaymentType(type) {
            return type.replace("_"," ");
        },
        showorderstatus(payment) {
            if (payment.pending) {
                return "Pending"
            }
            return ""
        }
    }
}
</script>
